import * as React from 'react';
import PropTypes from 'prop-types';

import { StaticQuery, graphql } from "gatsby"

import {
  Box,
} from '@mui/material';

import TemplateRow from "./TemplateRow"

import * as helpers from "../helpers"
import CONSTS from "../consts"


function PlainTextEmailTemplateRowProvider() {
  return (
    <StaticQuery
      query={graphql`
        query plainTextEmailTemplateForRowQuery {
          emailTemplatesPlainText: allDirectory(limit: 3 filter: {sourceInstanceName: {eq: "emailTemplatesPlainText"}, relativeDirectory: {eq: ""}}) {
            nodes {
              relativePath
              childFiles {
                name
                childTemplateData {
                  description
                  variables {
                    description
                    variable
                    example
                  }
                }
                childPlainText {
                  content
                }
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          emailTemplatePlainTextCount: allDirectory(
            filter: {sourceInstanceName: {eq: "emailTemplatesPlainText"}, relativeDirectory: {eq: ""}}
          ) {
            totalCount
          }
        }
      `}
      render={data => {
        const emailTemplates = helpers.normalizeTemplatesData(data.emailTemplatesPlainText.nodes);

        return (
          <Box
            sx={{
              paddingBottom: '50px'
            }}
          >
            <TemplateRow
              templates={emailTemplates}
              totalTemplates={data.emailTemplatePlainTextCount.totalCount}
              viewMoreTitle='Head over to the plain text template page for more'
              collectionTemplatePagePath={CONSTS.PATHS.PLANE_TEXT_EMAIL_TEMPLATE_COLLECTION}
              individualPagePath={CONSTS.PATHS.INDIVIDUAL_PLANE_TEXT_EMAIL_TEMPLATE}
            />
          </Box>
        )
      }}
    />
  );
}

PlainTextEmailTemplateRowProvider.propTypes = {

};

export default PlainTextEmailTemplateRowProvider;