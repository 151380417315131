/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import EmailTemplateRowProvider from '../components/EmailTemplateRowProvider';
import PlainTextEmailTemplateRowProvider from '../components/PlainTextEmailTemplateRowProvider';
import ArticleProvider from '../components/ArticleProvider';
import ImgExplanation from '../components/ImgExplanation';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div",
    h3: "h3",
    ul: "ul",
    li: "li",
    h4: "h4",
    ol: "ol"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "In this comprehensive review request email guide we start by explaining what a review request email is and how you send one."), "\n", React.createElement(_components.p, null, "We have review request email examples plus ", React.createElement("a", {
    href: "/",
    title: "Review request email examples"
  }, "free downloadable email templates"), "."), "\n", React.createElement(_components.p, null, "Read everything on this page and you will know exactly how to execute a successful review request strategy."), "\n", React.createElement(_components.h2, {
    id: "review-request-emails",
    style: {
      position: "relative"
    }
  }, "Review Request Emails", React.createElement(_components.a, {
    href: "#review-request-emails",
    "aria-label": "review request emails permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Review request emails are a type of email businesses send to customers encouraging them to post a review."), "\n", React.createElement(_components.p, null, "Request emails are typically sent at the conclusion of business, such as when the customer has purchased a product or a service has been competed."), "\n", React.createElement(_components.p, null, "This type of email is also often referred to as a review invitation email or feedback request email."), "\n", React.createElement(_components.p, null, "Regardless of what you call it the reason businesses send review requests is simple, asking for reviews leads to more reviews."), "\n", React.createElement(ImgExplanation, {
    explanation: "A chart that shows the increase in reviews once a business starts sending review requests."
  }, React.createElement("img", {
    src: "/reviews_per_month_chart_red.png",
    alt: "A chart that shows the increase in reviews once a business starts sending review requests."
  })), "\n", React.createElement(_components.h2, {
    id: "review-request-email-examples",
    style: {
      position: "relative"
    }
  }, "Review Request Email Examples", React.createElement(_components.a, {
    href: "#review-request-email-examples",
    "aria-label": "review request email examples permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "To get you started here are three samples of emails that ask a customer for a review."), "\n", React.createElement(_components.p, null, "There are plenty ", React.createElement("a", {
    href: "/",
    title: "Review request email examples"
  }, "more examples"), " to come."), "\n", React.createElement(_components.h3, {
    id: "example-1",
    style: {
      position: "relative"
    }
  }, "Example 1", React.createElement(_components.a, {
    href: "#example-1",
    "aria-label": "example 1 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(ImgExplanation, {
    explanation: "HTML review request email.",
    linkDestination: "/html-email-template/ht1a02c003f",
    linkText: "Go to template"
  }, React.createElement("img", {
    src: "/html_email_example.png",
    alt: "HTML review request email"
  })), "\n", React.createElement(_components.h3, {
    id: "example-2",
    style: {
      position: "relative"
    }
  }, "Example 2", React.createElement(_components.a, {
    href: "#example-2",
    "aria-label": "example 2 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(ImgExplanation, {
    explanation: "Funny review request email.",
    linkDestination: "/html-email-template/ht1b02d003g",
    linkText: "Go to template"
  }, React.createElement("img", {
    src: "/html_review_request_email_2.jpg",
    alt: "Funny review request email"
  })), "\n", React.createElement(_components.h3, {
    id: "example-3",
    style: {
      position: "relative"
    }
  }, "Example 3", React.createElement(_components.a, {
    href: "#example-3",
    "aria-label": "example 3 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(ImgExplanation, {
    explanation: "Regular plane text review request email.",
    linkDestination: "/plain-text-email-template/pt101f000",
    linkText: "Go to template"
  }, React.createElement("img", {
    src: "/text_only_review_request_email_example.png",
    alt: "Regular plane text review request email"
  })), "\n", React.createElement(_components.h2, {
    id: "why-ask-for-reviews",
    style: {
      position: "relative"
    }
  }, "Why Ask For Reviews?", React.createElement(_components.a, {
    href: "#why-ask-for-reviews",
    "aria-label": "why ask for reviews permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "It is not easy to buildup a large collection of reviews. Just ask the businesses that have a lot. They probably started focusing on reviews a long time ago."), "\n", React.createElement(_components.p, null, "There are two main reasons why asking helps businesses get more reviews."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "People are busy and need reminders"), "\n", React.createElement(_components.li, null, "Providing a link to the review submission page makes posting easier"), "\n"), "\n", React.createElement(_components.p, null, "Even when you use review requests it is still a numbers game."), "\n", React.createElement(_components.p, null, "Review requests still won't get you the most reviews over night, but they will increase the percentage of customers that post a review."), "\n", React.createElement(_components.p, null, "Make sure you are consistently requesting a review from every customer so you are never missing an opportunity."), "\n", React.createElement(_components.p, null, "The sooner you start the sooner you will achieve your goal."), "\n", React.createElement(_components.h3, {
    id: "protection-from-negative-reviews",
    style: {
      position: "relative"
    }
  }, "Protection From Negative Reviews", React.createElement(_components.a, {
    href: "#protection-from-negative-reviews",
    "aria-label": "protection from negative reviews permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "The large collection of positive reviews that comes from sending review request is great protection from the impact of a negative review."), "\n", React.createElement(_components.p, null, "The unfortunate truth is there is no pleasing some customers and no matter how good of work you do you may get the odd negative review."), "\n", React.createElement(_components.p, null, "Businesses worried about the impact of a negative review can take a proactive approach."), "\n", React.createElement(_components.p, null, "By sending a review request and building up a base of five star reviews you can mitigate the risk of a negative review."), "\n", React.createElement(_components.p, null, "A negative review will carry much less weight if it is the outlier amongst many other positive reviews."), "\n", React.createElement(_components.p, null, "So if the risk of a negative review is keeping you up at night start requesting reviews and you can sleep a little easier."), "\n", React.createElement(_components.h2, {
    id: "impact-of-reviews",
    style: {
      position: "relative"
    }
  }, "Impact of Reviews", React.createElement(_components.a, {
    href: "#impact-of-reviews",
    "aria-label": "impact of reviews permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Reviews are great but you can't pay bills with reviews."), "\n", React.createElement(_components.p, null, "The true measure of success for reviews is what they do for a business in the long run."), "\n", React.createElement(_components.p, null, "So what do they do? Well, reviews help build up customers trust in a business. An industry leading review rating also results in more exposure online."), "\n", React.createElement(_components.p, null, "In other words when customers are searching there is a better chance your business will show in the results and a better chance the customer will pick you."), "\n", React.createElement(_components.p, null, "That is why the data shows that an increase in reviews leads to more website visits and phone calls."), "\n", React.createElement(_components.p, null, "Reviews are great, but new customers are even better. That is ultimately why we all like reviews and why review requests are so important."), "\n", React.createElement(ImgExplanation, {
    explanation: "A graphic showing the relationship between Google reviews and leads from a Google business listing."
  }, React.createElement("img", {
    src: "/review_and_leads.png",
    alt: "A graphic showing the relationship between Google reviews and leads from a Google business listing."
  })), "\n", React.createElement(_components.h2, {
    id: "review-request-are-not",
    style: {
      position: "relative"
    }
  }, "Review Request Are Not...", React.createElement(_components.a, {
    href: "#review-request-are-not",
    "aria-label": "review request are not permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Review requests don't get bad businesses undeserved reviews."), "\n", React.createElement(_components.p, null, "Review requests only increase the percentage of customers that post reviews."), "\n", React.createElement(_components.p, null, "A business still needs to provide great customer service to get positive reviews."), "\n", React.createElement(_components.h2, {
    id: "sending-a-review-request-email",
    style: {
      position: "relative"
    }
  }, "Sending a Review Request Email", React.createElement(_components.a, {
    href: "#sending-a-review-request-email",
    "aria-label": "sending a review request email permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "It is time to talk details, how you actually ask for reviews via email."), "\n", React.createElement(_components.p, null, "There are only two requirements for a review request email, a pitch and a review link."), "\n", React.createElement(ImgExplanation, {
    explanation: "Illustration symbolizing review request emails"
  }, React.createElement("img", {
    src: "/review_requests_illustration.png",
    alt: "Illustration symbolizing review request emails"
  })), "\n", React.createElement(_components.h4, {
    id: "the-pitch",
    style: {
      position: "relative"
    }
  }, "The Pitch", React.createElement(_components.a, {
    href: "#the-pitch",
    "aria-label": "the pitch permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Your pitch is how you ask for the review in the body of the email."), "\n", React.createElement(_components.p, null, "At first it seems easy, but you might find it gets harder to find the correct words when it comes time to \"make the ask\"."), "\n", React.createElement(_components.p, null, "Not to worry, we have done the work for you with our collection of free ", React.createElement("a", {
    href: "/articles/review-request-email-templates#free-downloadable-templates",
    title: "The email template section of the article"
  }, "ready to send email templates"), "."), "\n", React.createElement(_components.p, null, "However, for those who are interested in writing their own requests, here are a few tips:"), "\n", React.createElement(_components.p, null, "Keep it short - Customers may not read your whole email."), "\n", React.createElement(_components.p, null, "Make it personal - At the very least make sure you use their name."), "\n", React.createElement(_components.p, null, "Be clear - The request is never going to work if the customer misses your point."), "\n", React.createElement(_components.h4, {
    id: "review-link",
    style: {
      position: "relative"
    }
  }, "Review Link", React.createElement(_components.a, {
    href: "#review-link",
    "aria-label": "review link permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "This is a link to the review submission page. It is a critical part of a review request."), "\n", React.createElement(_components.p, null, "The link is what makes the process of leaving a review significantly easier for your customers."), "\n", React.createElement(_components.p, null, "No need for them to Google your company, find your business listing and navigate to the review section."), "\n", React.createElement(_components.p, null, "Instead, just one click before they can start writing about their experience."), "\n", React.createElement(_components.p, null, "Including a link along side your request dramatically increases the odds you will get a review."), "\n", React.createElement(_components.p, null, "Also, ", React.createElement("b", null, "never pay for this"), ". Review request links are free and easy to retrieve."), "\n", React.createElement(_components.p, null, "For example here are ", React.createElement("a", {
    href: "https://support.google.com/business/answer/3474122",
    target: "_blank"
  }, "Googles instructions"), " on how to get your review link from them."), "\n", React.createElement(ImgExplanation, {
    explanation: "Screenshot of Googles review link in Google Business Listing admin."
  }, React.createElement("img", {
    src: "/google_review_link.png",
    alt: "Screenshot of Googles review link in Google Business Listing admin."
  })), "\n", React.createElement(_components.h2, {
    id: "maximize-conversion-rate",
    style: {
      position: "relative"
    }
  }, "Maximize Conversion Rate", React.createElement(_components.a, {
    href: "#maximize-conversion-rate",
    "aria-label": "maximize conversion rate permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "For review request emails conversion rate is the number of reviews you get divided by the number of customers you send requests to."), "\n", React.createElement(_components.p, null, "Businesses looking to maximize their conversion rate can do a lot to increase the chances of their request emails resulting in a review."), "\n", React.createElement(_components.p, null, "There are many factors that will impact your conversion rate."), "\n", React.createElement(_components.p, null, "Here are three examples:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "When you send your requests"), "\n", React.createElement(_components.li, null, "How well your emails match your companies brand"), "\n", React.createElement(_components.li, null, "If you send follow up emails"), "\n"), "\n", React.createElement(_components.p, null, "Read our ", React.createElement("a", {
    href: "/articles/ten-review-requests-mistakes-to-avoid",
    title: "Article about improving review request conversion rate"
  }, "optimizing review requests"), " article for 10 tips and tricks."), "\n", React.createElement(_components.h2, {
    id: "free-downloadable-templates",
    style: {
      position: "relative"
    }
  }, "Free Downloadable Templates", React.createElement(_components.a, {
    href: "#free-downloadable-templates",
    "aria-label": "free downloadable templates permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(ImgExplanation, {
    explanation: "Collage featuring some of our 250+ free review request email templates and examples"
  }, React.createElement("img", {
    src: "/review_request_email_template_examples.jpg",
    alt: "Collage featuring some of our 250+ free review request email templates and examples"
  })), "\n", React.createElement(_components.p, null, "Browse our review request emails and choose the right one for your business."), "\n", React.createElement(_components.p, null, "All of our templates are free, downloadable without an account and there are sending instructions with each template."), "\n", React.createElement(_components.p, null, "We offer two types of templates, HTML and plain text."), "\n", React.createElement(_components.p, null, "Keep scrolling to learn more about which type of template is the best option for you."), "\n", React.createElement(_components.h2, {
    id: "html-templates",
    style: {
      position: "relative"
    }
  }, "HTML Templates", React.createElement(_components.a, {
    href: "#html-templates",
    "aria-label": "html templates permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(EmailTemplateRowProvider), "\n", React.createElement(_components.p, null, "To use one of these HTML template all you need to do is:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Click on the template you like to expand it."), "\n", React.createElement(_components.li, null, "Click the Use This Template and then Download button."), "\n", React.createElement(_components.li, null, "Once downloaded replace the placeholders with the appropriate values."), "\n", React.createElement(_components.li, null, "Check out our ", React.createElement("a", {
    href: "/articles/html-vs-plain-text-emails#sending-an-html-email",
    title: "Article explaining how to send a HTML email"
  }, "how to send a HTML email"), " article for the rest."), "\n"), "\n", React.createElement(_components.h2, {
    id: "plain-text-templates",
    style: {
      position: "relative"
    }
  }, "Plain Text Templates", React.createElement(_components.a, {
    href: "#plain-text-templates",
    "aria-label": "plain text templates permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(PlainTextEmailTemplateRowProvider), "\n", React.createElement(_components.p, null, "To use one of these plain text templates all you need to do is:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Click on the template you like to expand it."), "\n", React.createElement(_components.li, null, "Click the Use Template and then Copy button."), "\n", React.createElement(_components.li, null, "Paste it in your email application."), "\n", React.createElement(_components.li, null, "Replace the placeholders with the appropriate values."), "\n", React.createElement(_components.li, null, "Hit send."), "\n"), "\n", React.createElement(_components.h2, {
    id: "html-vs-plain-text-email",
    style: {
      position: "relative"
    }
  }, "HTML vs. Plain Text Email", React.createElement(_components.a, {
    href: "#html-vs-plain-text-email",
    "aria-label": "html vs plain text email permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "You will notice the email templates are split into two categories, HTML email and Plain Text."), "\n", React.createElement(_components.p, null, "The quick explanation is, HTML emails can have more styling, but are more difficult to send. Whereas, anyone can send a plain text email, but they offer very limited styling options."), "\n", React.createElement(_components.p, null, "You can read more about the difference between the two in our ", React.createElement("a", {
    href: "/articles/html-vs-plain-text-emails",
    title: "Article comparing HTML and plain text emails"
  }, "HTML vs. Plain Text Emails"), " article."), "\n", React.createElement(_components.h2, {
    id: "customer-experience",
    style: {
      position: "relative"
    }
  }, "Customer Experience", React.createElement(_components.a, {
    href: "#customer-experience",
    "aria-label": "customer experience permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "When sending review request emails, it is important to keep the experience of your customer front of mind."), "\n", React.createElement(_components.p, null, "As long as a business does this then sending review requests is an ethical business practice."), "\n", React.createElement(_components.p, null, "Just make sure you are not pestering customers for reviews and do your best to avoid sending unnecessary emails"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Always give customers the option to opt out of receiving future emails"), "\n", React.createElement(_components.li, null, "If you send follow up requests make sure there is a reasonable limit"), "\n", React.createElement(_components.li, null, "Make sure you stop emailing customers once they have posted a review (keep in mind the name on the review could be different from the one you know)"), "\n"), "\n", React.createElement(_components.h2, {
    id: "before-you-send",
    style: {
      position: "relative"
    }
  }, "Before You Send", React.createElement(_components.a, {
    href: "#before-you-send",
    "aria-label": "before you send permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Here are a few things to keep in mind when sending review requests."), "\n", React.createElement(_components.h4, {
    id: "review-platforms-terms-of-service",
    style: {
      position: "relative"
    }
  }, "Review Platforms Terms of Service", React.createElement(_components.a, {
    href: "#review-platforms-terms-of-service",
    "aria-label": "review platforms terms of service permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Most review platforms allow a business to request reviews from customers. Google Business even gives you a sharable link to your review page."), "\n", React.createElement(_components.p, null, "However, not every platform allows the activity. So make sure you familiarize yourself with the terms of service for your platform of choice."), "\n", React.createElement(_components.h4, {
    id: "email-laws",
    style: {
      position: "relative"
    }
  }, "Email Laws", React.createElement(_components.a, {
    href: "#email-laws",
    "aria-label": "email laws permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Business should familiarize themselves with laws that apply to emails being sent from a company to a customer in their jurisdiction."), "\n", React.createElement(_components.p, null, "The general idea of many of these laws is that the customer must have previously consented to receiving these communications from your business."), "\n", React.createElement(_components.p, null, "Of course we are not lawyers and this is not professional legal advice. Please seek the opinion of a professional."), "\n", React.createElement(_components.h2, {
    id: "final-thoughts",
    style: {
      position: "relative"
    }
  }, "Final Thoughts", React.createElement(_components.a, {
    href: "#final-thoughts",
    "aria-label": "final thoughts permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "At this point we have talked about what a review request emails is and how you send them."), "\n", React.createElement(_components.p, null, "You have seen examples and even had the the chance to browse our collections of free downloadable ", React.createElement("a", {
    href: "/html-review-request-emails",
    title: "HTML email template collection page"
  }, "HTML"), " and ", React.createElement("a", {
    href: "/plain-text-review-request-emails",
    title: "Plain text email template collection page"
  }, "plain text"), " email templates."), "\n", React.createElement(_components.p, null, "We also discussed how to ", React.createElement("a", {
    href: "/articles/review-request-email-templates#maximize-conversion-rate",
    title: "Review request email optimization article"
  }, "maximize your conversion rate"), "."), "\n", React.createElement(_components.p, null, "You are now well equipped to launch a successful review request strategy, collect more reviews and grow your business."), "\n", React.createElement(_components.h3, {
    id: "keep-reading",
    style: {
      position: "relative"
    }
  }, "Keep Reading", React.createElement(_components.a, {
    href: "#keep-reading",
    "aria-label": "keep reading permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Here are a few other resources you may find useful."), "\n", React.createElement(ArticleProvider));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
