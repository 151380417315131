import * as React from 'react';
import PropTypes from 'prop-types';

import { StaticQuery, graphql } from "gatsby"

import {
  Grid,
} from '@mui/material';

import ArticleCard from "../components/ArticleCard"


function ArticleProvider() {
  return (
    <StaticQuery
      query={graphql`
        query keepReadingArticlesQuery {
          keepReadingArticles: allMdx(sort: {order: ASC, fields: frontmatter___menu}, filter: {frontmatter: {article: {eq: true}}}) {
            nodes {
              frontmatter {
                title
                author
                blurb
              }
              fields {
                pagePath
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <Grid container spacing={4}>
            {data.keepReadingArticles.nodes.map((post) => {
              return (
                <ArticleCard
                  post={post}
                />
              );
            })}
          </Grid>
        )
      }}
    />
  );
}

ArticleProvider.propTypes = {

};

export default ArticleProvider;