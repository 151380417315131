/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"

import {
  Box,
} from '@mui/material';

import {
  Button,
} from 'gatsby-theme-material-ui';

const ImgExplanation = ({ children, explanation, linkText = null, linkDestination = null }) => {

  return (
    <Box
      sx={{
        padding: '2px',
        marginBottom: '50px',
        background: '#e1e1e1',
        fontSize: '.8rem',
      }}
    >
      {children}
      <Box
        sx={{
          padding: '0px 2px 2px 2px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {explanation}

        {linkDestination && <Button
          variant='contained'
          to={linkDestination}
          sx={{
            margin: '5px',
            flexShrink: 0
          }}
        >
          {linkText ? linkText : 'View more'}
        </Button>}
      </Box>
    </Box>
  )
}

ImgExplanation.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ImgExplanation
