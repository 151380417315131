import * as React from 'react';
import PropTypes from 'prop-types';

import { StaticQuery, graphql } from "gatsby"

import {
  Box,
} from '@mui/material';

import TemplateRow from "../components/TemplateRow"

import * as helpers from "../helpers"
import CONSTS from "../consts"

function EmailTemplateRowProvider() {
  return (
    <StaticQuery
      query={graphql`
        query emailTemplateForRowQuery {
          emailTemplates: allDirectory(limit: 3 filter: {sourceInstanceName: {eq: "emailTemplates"}, relativeDirectory: {eq: ""}, relativePath: {in: ["ht1a02a103d", "ht1c02c003b", "ht1e02b103c"]}}) {
            nodes {
              relativePath
              childFiles {
                name
                childTemplateData {
                  description
                  variables {
                    description
                    variable
                    example
                  }
                }
                childPlainText {
                  content
                }
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          emailTemplateCount: allDirectory(
            filter: {sourceInstanceName: {eq: "emailTemplates"}, relativeDirectory: {eq: ""}}
          ) {
            totalCount
          }
        }
      `}
      render={data => {
        const emailTemplates = helpers.normalizeTemplatesData(data.emailTemplates.nodes);

        return (
          <Box
            sx={{
              paddingBottom: '50px'
            }}
          >
            <TemplateRow
              templates={emailTemplates}
              totalTemplates={data.emailTemplateCount.totalCount}
              viewMoreTitle='Head over to the HTML template page for more'
              collectionTemplatePagePath={CONSTS.PATHS.HTML_EMAIL_TEMPLATE_COLLECTION}
              individualPagePath={CONSTS.PATHS.INDIVIDUAL_HTML_EMAIL_TEMPLATE}
            />
          </Box>
        )
      }}
    />
  );
}

EmailTemplateRowProvider.propTypes = {

};

export default EmailTemplateRowProvider;